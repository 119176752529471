import revive_payload_client_58OKyl5JqK from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.11.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.1_r4epbgxmqvc56xslfewlkuuoi4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_daCfVUcdeI from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.11.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.1_r4epbgxmqvc56xslfewlkuuoi4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_6ivcoR1h1c from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.11.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.1_r4epbgxmqvc56xslfewlkuuoi4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_7cncS74e4p from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.11.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.1_r4epbgxmqvc56xslfewlkuuoi4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_9nkHZhZbAE from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.11.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.1_r4epbgxmqvc56xslfewlkuuoi4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_2kNC1v7RV5 from "/__w/locokit/locokit/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.18.0_typescript@5.4.5_vue@3.4.31_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/__w/locokit/locokit/packages/nuxt-locokit/playground/.nuxt/components.plugin.mjs";
import prefetch_client_WTruMwIeR7 from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.11.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.1_r4epbgxmqvc56xslfewlkuuoi4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _6_middlewares_15uNakrgOy from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/6_middlewares.ts";
import _5_vee_validate_RKLw6vvvSL from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/5_vee-validate.ts";
import _3_i18n_WH6lZYC9B9 from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/3_i18n.ts";
import _2_directive_ZENN733d4x from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/2_directive.ts";
import _1_error_AAMB8iXHMY from "/__w/locokit/locokit/packages/nuxt-locokit/src/runtime/plugins/1_error.ts";
import primevue_plugin_egKpok8Auk from "/__w/locokit/locokit/packages/nuxt-locokit/playground/.nuxt/primevue-plugin.mjs";
import plugin_client_xKEzyna6yI from "/__w/locokit/locokit/node_modules/.pnpm/nuxt-primevue@0.2.2_rollup@4.18.0_vue@3.4.31_typescript@5.4.5__webpack-sources@3.2.3/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import chunk_reload_client_paIrN1m6uI from "/__w/locokit/locokit/node_modules/.pnpm/nuxt@3.11.1_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.2_encoding@0.1.1_r4epbgxmqvc56xslfewlkuuoi4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
export default [
  revive_payload_client_58OKyl5JqK,
  unhead_daCfVUcdeI,
  router_6ivcoR1h1c,
  payload_client_7cncS74e4p,
  check_outdated_build_client_9nkHZhZbAE,
  plugin_vue3_2kNC1v7RV5,
  components_plugin_KR1HBZs4kY,
  prefetch_client_WTruMwIeR7,
  _6_middlewares_15uNakrgOy,
  _5_vee_validate_RKLw6vvvSL,
  _3_i18n_WH6lZYC9B9,
  _2_directive_ZENN733d4x,
  _1_error_AAMB8iXHMY,
  primevue_plugin_egKpok8Auk,
  plugin_client_xKEzyna6yI,
  chunk_reload_client_paIrN1m6uI
]