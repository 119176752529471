import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPrimeAutoComplete, LazyPrimeCalendar, LazyPrimeCascadeSelect, LazyPrimeCheckbox, LazyPrimeChips, LazyPrimeColorPicker, LazyPrimeDropdown, LazyPrimeInputGroup, LazyPrimeInputGroupAddon, LazyPrimeInputMask, LazyPrimeInputNumber, LazyPrimeInputSwitch, LazyPrimeInputText, LazyPrimeKnob, LazyPrimeListbox, LazyPrimeMultiSelect, LazyPrimePassword, LazyPrimeRadioButton, LazyPrimeRating, LazyPrimeSelectButton, LazyPrimeSlider, LazyPrimeTextarea, LazyPrimeToggleButton, LazyPrimeTreeSelect, LazyPrimeTriStateCheckbox, LazyPrimeButton, LazyPrimeSpeedDial, LazyPrimeSplitButton, LazyPrimeColumn, LazyPrimeRow, LazyPrimeColumnGroup, LazyPrimeDataTable, LazyPrimeDataView, LazyPrimeDataViewLayoutOptions, LazyPrimeOrderList, LazyPrimeOrganizationChart, LazyPrimePaginator, LazyPrimePickList, LazyPrimeTree, LazyPrimeTreeTable, LazyPrimeTimeline, LazyPrimeVirtualScroller, LazyPrimeAccordion, LazyPrimeAccordionTab, LazyPrimeCard, LazyPrimeDeferredContent, LazyPrimeDivider, LazyPrimeFieldset, LazyPrimePanel, LazyPrimeScrollPanel, LazyPrimeSplitter, LazyPrimeSplitterPanel, LazyPrimeTabView, LazyPrimeTabPanel, LazyPrimeToolbar, LazyPrimeConfirmDialog, LazyPrimeConfirmPopup, LazyPrimeDialog, LazyPrimeDynamicDialog, LazyPrimeOverlayPanel, LazyPrimeSidebar, LazyPrimeFileUpload, LazyPrimeBreadcrumb, LazyPrimeContextMenu, LazyPrimeDock, LazyPrimeMenu, LazyPrimeMenubar, LazyPrimeMegaMenu, LazyPrimePanelMenu, LazyPrimeSteps, LazyPrimeTabMenu, LazyPrimeTieredMenu, LazyPrimeMessage, LazyPrimeInlineMessage, LazyPrimeToast, LazyPrimeCarousel, LazyPrimeGalleria, LazyPrimeImage, LazyPrimeAvatar, LazyPrimeAvatarGroup, LazyPrimeBadge, LazyPrimeBlockUI, LazyPrimeChip, LazyPrimeInplace, LazyPrimeScrollTop, LazyPrimeSkeleton, LazyPrimeProgressBar, LazyPrimeProgressSpinner, LazyPrimeTag, LazyPrimeTerminal } from '#components'
const lazyGlobalComponents = [
  ["PrimeAutoComplete", LazyPrimeAutoComplete],
["PrimeCalendar", LazyPrimeCalendar],
["PrimeCascadeSelect", LazyPrimeCascadeSelect],
["PrimeCheckbox", LazyPrimeCheckbox],
["PrimeChips", LazyPrimeChips],
["PrimeColorPicker", LazyPrimeColorPicker],
["PrimeDropdown", LazyPrimeDropdown],
["PrimeInputGroup", LazyPrimeInputGroup],
["PrimeInputGroupAddon", LazyPrimeInputGroupAddon],
["PrimeInputMask", LazyPrimeInputMask],
["PrimeInputNumber", LazyPrimeInputNumber],
["PrimeInputSwitch", LazyPrimeInputSwitch],
["PrimeInputText", LazyPrimeInputText],
["PrimeKnob", LazyPrimeKnob],
["PrimeListbox", LazyPrimeListbox],
["PrimeMultiSelect", LazyPrimeMultiSelect],
["PrimePassword", LazyPrimePassword],
["PrimeRadioButton", LazyPrimeRadioButton],
["PrimeRating", LazyPrimeRating],
["PrimeSelectButton", LazyPrimeSelectButton],
["PrimeSlider", LazyPrimeSlider],
["PrimeTextarea", LazyPrimeTextarea],
["PrimeToggleButton", LazyPrimeToggleButton],
["PrimeTreeSelect", LazyPrimeTreeSelect],
["PrimeTriStateCheckbox", LazyPrimeTriStateCheckbox],
["PrimeButton", LazyPrimeButton],
["PrimeSpeedDial", LazyPrimeSpeedDial],
["PrimeSplitButton", LazyPrimeSplitButton],
["PrimeColumn", LazyPrimeColumn],
["PrimeRow", LazyPrimeRow],
["PrimeColumnGroup", LazyPrimeColumnGroup],
["PrimeDataTable", LazyPrimeDataTable],
["PrimeDataView", LazyPrimeDataView],
["PrimeDataViewLayoutOptions", LazyPrimeDataViewLayoutOptions],
["PrimeOrderList", LazyPrimeOrderList],
["PrimeOrganizationChart", LazyPrimeOrganizationChart],
["PrimePaginator", LazyPrimePaginator],
["PrimePickList", LazyPrimePickList],
["PrimeTree", LazyPrimeTree],
["PrimeTreeTable", LazyPrimeTreeTable],
["PrimeTimeline", LazyPrimeTimeline],
["PrimeVirtualScroller", LazyPrimeVirtualScroller],
["PrimeAccordion", LazyPrimeAccordion],
["PrimeAccordionTab", LazyPrimeAccordionTab],
["PrimeCard", LazyPrimeCard],
["PrimeDeferredContent", LazyPrimeDeferredContent],
["PrimeDivider", LazyPrimeDivider],
["PrimeFieldset", LazyPrimeFieldset],
["PrimePanel", LazyPrimePanel],
["PrimeScrollPanel", LazyPrimeScrollPanel],
["PrimeSplitter", LazyPrimeSplitter],
["PrimeSplitterPanel", LazyPrimeSplitterPanel],
["PrimeTabView", LazyPrimeTabView],
["PrimeTabPanel", LazyPrimeTabPanel],
["PrimeToolbar", LazyPrimeToolbar],
["PrimeConfirmDialog", LazyPrimeConfirmDialog],
["PrimeConfirmPopup", LazyPrimeConfirmPopup],
["PrimeDialog", LazyPrimeDialog],
["PrimeDynamicDialog", LazyPrimeDynamicDialog],
["PrimeOverlayPanel", LazyPrimeOverlayPanel],
["PrimeSidebar", LazyPrimeSidebar],
["PrimeFileUpload", LazyPrimeFileUpload],
["PrimeBreadcrumb", LazyPrimeBreadcrumb],
["PrimeContextMenu", LazyPrimeContextMenu],
["PrimeDock", LazyPrimeDock],
["PrimeMenu", LazyPrimeMenu],
["PrimeMenubar", LazyPrimeMenubar],
["PrimeMegaMenu", LazyPrimeMegaMenu],
["PrimePanelMenu", LazyPrimePanelMenu],
["PrimeSteps", LazyPrimeSteps],
["PrimeTabMenu", LazyPrimeTabMenu],
["PrimeTieredMenu", LazyPrimeTieredMenu],
["PrimeMessage", LazyPrimeMessage],
["PrimeInlineMessage", LazyPrimeInlineMessage],
["PrimeToast", LazyPrimeToast],
["PrimeCarousel", LazyPrimeCarousel],
["PrimeGalleria", LazyPrimeGalleria],
["PrimeImage", LazyPrimeImage],
["PrimeAvatar", LazyPrimeAvatar],
["PrimeAvatarGroup", LazyPrimeAvatarGroup],
["PrimeBadge", LazyPrimeBadge],
["PrimeBlockUI", LazyPrimeBlockUI],
["PrimeChip", LazyPrimeChip],
["PrimeInplace", LazyPrimeInplace],
["PrimeScrollTop", LazyPrimeScrollTop],
["PrimeSkeleton", LazyPrimeSkeleton],
["PrimeProgressBar", LazyPrimeProgressBar],
["PrimeProgressSpinner", LazyPrimeProgressSpinner],
["PrimeTag", LazyPrimeTag],
["PrimeTerminal", LazyPrimeTerminal],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
